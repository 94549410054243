import React from 'react';
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Navbar from '../components/navbar/Navbar';

function PrivacyPage() {

    return (
        <>
            <Helmet>
                <title>Compra - Privacy</title>
            </Helmet>
            <div className="bg-white h-full">
                <div className="relative h-full flex flex-col">
                    <Navbar />
                    <main className="flex-1">
                        <div className="relative bg-gray-50 py-8 sm:py-12 lg:py-16 h-full">
                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-4xl">
                                <div>
                                    <h1 className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                        Privacy Policy
                                    </h1>
                                </div>
                                <div className="mt-2 sm:mt-12">
                                    <div className="mt-3 sm:mt-12 text-left mb-16">
                                        <p className="text-gray-500 mb-4">Please read this document carefully. It is the Compra Privacy Policy (the "Privacy Policy") and explains how personal information is collected, used and disclosed by Compra with respect to your access and use of this web site (located at www.compra.co.nz) (the "Site").</p>

                                        <p className="text-gray-500 mb-4">Compra Technologies Inc., a company registered under applicable laws ("Compra", "we", "our", or "us") reserves the right to change or modify this Privacy Policy from time to time, and we will notify users ("you") of material changes on the Site before they take effect. We will occasionally update this Privacy Policy to reflect changes in our practices. If we make any material changes in the way we collect, use, and/or share your personal information, we will notify you by sending an email to the email address you most recently provided us in your account, profile or registration (unless we do not have such an email address), and/or by posting notice of the changes on the Site. Your use of the Site and any of the information, products or various services, if any (collectively, the "Services") provided by us will be subject to the most current version of this Privacy Policy posted on the Site at the time of your use. We recommend that you check the site from time to time to inform yourself of any changes in this Privacy Policy or any of our other policies.</p>
                                    </div>
                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">What Is Personal Information?</h2>
                                        <p className="text-gray-500 mb-4">"Personal information" means information that specifically identifies an individual (such as a name, address, telephone number, mobile number or email address) or information about that individual that is directly linked to personally identifiable information. Personal information does not include "aggregate" information, which is data we collect about the use of the Site and the Services. Our Privacy Policy does not restrict or limit our collection and use of aggregate information.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">What Personal Information Do We Collect?</h2>
                                        <p className="text-gray-500 mb-4">We collect certain information you voluntarily provide to us including, without limitation, the information you enter when you create an account and profile, send us an email, request user support or submit or post any other content to the Site or use the Services.</p>
                                        <p className="text-gray-500 mb-4">We automatically collect certain information when you use the Site and the Services including, without limitation, your Internet Protocol (IP) address, your operating system, the browser type, the address of a referring site and your activity on the Site. We also collect information from mobile phone users in a similar manner to optimize the mobile experience. Beyond mobile phone numbers, we collect mobile screen resolution, device make and manufacturer, as well as the device's unique identifier and, with your permission, your location. We treat this information as personal information if we combine it with or link it to any of the identifying information mentioned above. Otherwise, it is used in the aggregate only.</p>
                                        <p className="text-gray-500 mb-4">We may also receive personal information from third parties about their users, and may combine this information with other personal information we maintain about you. If we do so, this Privacy Policy will govern the combined information.</p>
                                    </div>
                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">What Aggregated and Non-Personal Information Do We Collect?</h2>
                                        <p className="text-gray-500 mb-4">We may use standard Internet technology, such as web beacons and other similar technologies, to track your use of the Site and the Services. We also may include web beacons in promotional email messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer visitors to our Site to deliver targeted advertisements and to measure the overall effectiveness of our online advertising, content, programming or other activities.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">Children</h2>
                                        <p className="text-gray-500 mb-4">The Site is not directed at children. Consistent with the Federal Children's Online Privacy Protection Act of 1998 (COPPA), Compra will not knowingly request or collect personally identifiable information from any child under age 13 without requiring parental consent. Any person who provides his or her personal information to use through the Site or the Services represents that he or she is older than 12 years of age.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">How Do We Use the Personal Information We Collect?</h2>
                                        <p className="text-gray-500 mb-4">In General. We may use information that we collect about you to deliver the products and services that you have requested, manage your account and provide you with customer support, perform research and analysis about your use of, or interest in, our products, services or content, or products, services or content offered by others, communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you either from us or other third parties, tailor your user experience, develop and display third-party content and advertising tailored to your interests on the Site and other sites, enforce our Terms and Conditions, manage our business and perform functions as otherwise described to you at the time of collection.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">What Personal Information Do We Share With Third Parties?</h2>
                                        <p className="text-gray-500 mb-4">We want you to understand when and with whom we may share personal or other information we have collected about you or your activities on the Site or while using the Services. We do not share your personal information with others except as indicated below or when we inform you and give you an opportunity to opt out of having your personal information shared. We may share personal information with:</p>
                                        <ul>
                                            <li className="text-gray-500 mb-4">Authorized service providers: We may share your personal information with our authorized service providers that perform certain services on our behalf. These services may include fulfilling orders, processing credit card payments, providing customer service and marketing assistance, performing business analysis, supporting the functionality of the Site and the Services, and supporting surveys and other features offered through the Site. These service providers may have access to personal information needed to perform their functions but are not permitted to share or use such information for any other purposes.</li>
                                            <li className="text-gray-500 mb-4">Other Situations. We also may disclose your information in response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law.</li>
                                        </ul>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">Your Control</h2>
                                        <p className="text-gray-500 mb-4">You can choose not to provide us with certain information, but that may result in you being unable to use certain features of the Site and the Services because such information may be required in order for you to register as a member; purchase products or services; participate in a survey; ask a question; or initiate other transactions on the Site or through the Services.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">Links to Other Sites</h2>
                                        <p className="text-gray-500 mb-4">The Site and the Services may contain links to other sites. Any personal information you provide on the linked pages is provided directly to that third party and is subject to that third party's privacy policy. This Privacy Policy does not apply to such linked sites, and we are not responsible for the content or privacy and security practices and policies of these sites or any other sites that are linked to from our Site.</p>
                                    </div>
                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">What Steps Do We Take To Protect Your Information Online?</h2>
                                        <p className="text-gray-500 mb-4">We take reasonable measures to protect your personal information in an effort to prevent loss, misuse and unauthorized access, disclosure, alteration and destruction. Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other type of misuse. If we become aware of a security systems breach, we may attempt to notify you electronically so that you can take appropriate protective steps.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">International Visitors</h2>
                                        <p className="text-gray-500 mb-4">The Site and the Services are hosted in and provided from New Zealand. If you use the Site or the Services from the European Union or other regions with laws governing data collection and use that may differ from New Zealand law, please note that you are transferring your personal data to New Zealand. New Zealand does not have the same data protection laws as the EU and some other regions. By providing your personal information, you consent to the transfer of your personal data to New Zealand and the use of your personal information, in accordance with this Privacy Policy.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">Information Retention and Disposal</h2>
                                        <p className="text-gray-500 mb-4">We may retain personal information for as long as we have a business need for it or as applicable laws or regulations or government orders require us to retain it. Additionally, the Site and/or the Services may enable you (or other users) to save information including information that you have made public or allowed certain users to access or see.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">What Choices Do You Have Regarding the Use of Your Information?</h2>
                                        <p className="text-gray-500 mb-4">You may "opt out" of receiving marketing or promotional emails from us by following the instructions in those emails or by emailing us at <a className="text-blue-400 hover:text-blue-300" href="mailto:info@compra.co.nz">info@compra.co.nz</a>. If you opt out, we may still send you non-promotional emails, such as emails about your accounts or our ongoing business relations.</p>
                                    </div>

                                    <div className="text-left mb-16">
                                        <h2 className="font-bold text-3xl mb-6 text-center">Questions?</h2>
                                        <p className="text-gray-500 mb-4">If you have any questions about our Privacy Policy, please contact us at <a className="text-blue-400 hover:text-blue-300" href="mailto:info@compra.co.nz">info@compra.co.nz</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default PrivacyPage;