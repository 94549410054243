import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";
import { EnvelopeOpenIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";


export default function JobCreatedPage() {
    const timeline = [
        {
            id: 1,
            content: 'Awaiting Email Verification',
            icon: EnvelopeOpenIcon,
            iconBackground: 'bg-amber-500',
        },
        {
            id: 2,
            content: 'Activate Alert',
            icon: GlobeAltIcon,
            iconBackground: 'bg-gray-500',
        },
    ];

    return (
        <>
            <Helmet>
                <title>Compra - Job Created</title>
            </Helmet>
            <div className="bg-gray-50 h-full flex flex-col">
                <Navbar />
                <main className="flex-1">
                    {/* Side-by-side grid */}
                    <div className="bg-gray-50">
                        <div className="max-w-md mx-auto py-16 px-4 sm:max-w-3xl sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Your job is almost ready!</h3>
                                    <div className="mt-2">
                                        We have sent you an email to confirm your identity, please follow the activation link to begin monitoring.
                                    </div>
                                    <div className="">
                                        <div className="px-6 py-5 sm:flex sm:items-start sm:justify-between">
                                            <h4 className="sr-only">Visa</h4>
                                            <div className="flow-root">
                                                <ul className="-mb-8">
                                                    {timeline.map((event, eventIdx) => (
                                                        <li key={event.id}>
                                                            <div className="relative pb-8">
                                                                {eventIdx !== timeline.length - 1 ? (
                                                                    <span className="absolute top-4 left-7 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                                ) : null}
                                                                <div className="relative flex space-x-3">
                                                                    <div>
                                                                        <span
                                                                            className={'h-14 w-14 rounded-full flex items-center justify-center ring-8 ring-white' + (event.iconBackground ? ` ${event.iconBackground}` : '')}
                                                                        >
                                                                            <event.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="min-w-0 flex-1 flex self-center">
                                                                        <div>
                                                                            <p className="text-lg text-gray-500 ">
                                                                                {event.content}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}