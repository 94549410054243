import React, { FC } from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface FrequencySelectorProps {
    setFrequency: (val: FrequencyItem) => void;
    frequency?: FrequencyItem;
    className?: string;
    buttonClassName?: string;
}

export interface FrequencyItem {
    name: string;
    value: number;
}

export const frequencies: FrequencyItem[] = [
    {
        name: "5 Mins",
        value: 5
    },
    {
        name: "15 Mins",
        value: 15
    },
    {
        name: "30 Mins",
        value: 30
    },
    {
        name: "1 Hour",
        value: 60
    },
    {
        name: "4 Hour",
        value: 240
    },
    {
        name: "12 Hours",
        value: 720
    },
    {
        name: "1 Day",
        value: 1440
    }
];

const FrequencySelector: FC<FrequencySelectorProps> = (props) => {

    return (
        <Menu as="div" id="frequency" className={"relative inline-block text-left w-full sm:w-auto" + (props.className ? ` ${props.className}` : "")}>
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className={"inline-flex sm:justify-center w-full rounded-md border shadow-sm px-4 py-3 bg-white text-base rounded-md placeholder-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none  " + (open ? " ring-cyan-500 border-cyan-500" : " border-gray-300") + (props.buttonClassName ? ` ${props.buttonClassName}` : "")}>
                            <span className="mr-2">{props.frequency ? props.frequency.name : "Frequency"}</span>
                            <ChevronDownIcon className="-mr-1 ml-auto mt-1 h-4 w-5 text-gray-400" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
                            {
                                frequencies.map(x => {
                                    const active = x.name === props.frequency?.name;
                                    return (
                                        <div className="cursor-pointer" key={x.name}>
                                            <Menu.Item>
                                                <div
                                                    className={"block px-4 py-2 text-sm" + (active ? ' bg-gray-100 text-gray-900' : ' text-gray-700')}
                                                    onClick={() => props.setFrequency(x)}
                                                >
                                                    {x.name}
                                                </div>
                                            </Menu.Item>
                                        </div>
                                    );
                                })
                            }
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default FrequencySelector;