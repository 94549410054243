import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";
import { useGlobalContext } from "../providers/GlobalContext";
import { useEffect, useState } from "react";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

export default function CheckoutPage() {
  const { createCheckoutSession, interceptorLoaded, authSettings, cart, user } = useGlobalContext();
  const [message, setMessage] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  const activePlan = user?.activePlan;
  const navigate = useNavigate();

  useEffect(() => {
    if (authSettings?.stripePublicKey) {
      const stripePromise = loadStripe(authSettings.stripePublicKey);
      setStripePromise(stripePromise);
    }
  }, [authSettings]);

  useEffect(() => {
    if (createCheckoutSession && interceptorLoaded && navigate) {
      if (
        cart?.subscriptions &&
        cart?.subscriptions.length > 0 &&
        activePlan?.planId === cart?.subscriptions[0].planId
      ) {
        setMessage("You already have an active subscription to this plan...");
        navigate("/plans");
      } else if (!!cart.subscriptions?.length || !!cart.vouchers?.length) {
        createCheckoutSession().then((c) => setClientSecret(c.data.clientSecret));
      } else {
        setMessage("Your cart appears to be empty...");
        navigate("/plans");
      }
    }
  }, [createCheckoutSession, interceptorLoaded, cart, navigate, activePlan]);

  return (
    <>
      <Helmet>
        <title>Compra - Cart</title>
      </Helmet>
      <div className="bg-gray-50 h-full flex flex-col">
        <Navbar />
        <main className="flex-1 bg-gray-100">
          <div className="max-w-md mx-auto py-16 px-4 sm:max-w-3xl sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 h-full">
            <div className="bg-white shadow sm:rounded-lg h-full">
              <div className="px-4 py-5 sm:p-6 relative text-center min-h-[100%]">
                {!!cart.subscriptions?.length || !!cart.vouchers?.length ? (
                  <>
                    {clientSecret && stripePromise && (
                      <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                        <EmbeddedCheckout />
                      </EmbeddedCheckoutProvider>
                    )}
                  </>
                ) : (
                  <div>{message}</div>
                )}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
