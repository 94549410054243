import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import { Disclosure, RadioGroup } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import { classNames } from "../utils/tailwind";
import { PlanEntity, useGlobalContext } from "../providers/GlobalContext";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequestRedirect } from "../config/authConfig";
import { useNavigate } from "react-router-dom";

const pricing = {
  frequencies: [
    { value: "monthly", label: "Monthly", priceSuffix: "/month" },
    { value: "yearly", label: "Annually", priceSuffix: "/year" },
  ],
};
const faqs = [
  {
    question: "What happens if I run out of checks?",
    answer: "Your monitored jobs will be deactivated until your plan rolls over, or you purchase additional checks.",
  },
];
function PricingPage() {
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);
  const { pricingPlans, getPricingPlans, user, authSettings, updateCart } = useGlobalContext();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const navigate = useNavigate();

  const handleLogin = useCallback(
    (pathname: string) => {
      if (authSettings?.profileSigninSignupUrl) {
        const email: any = activeAccount?.idTokenClaims?.email ?? undefined;
        instance
          .loginRedirect(loginRequestRedirect(authSettings.profileSigninSignupUrl, pathname, undefined, email))
          .catch((e) => {
            console.error(e);
          });
      }
    },
    [authSettings?.profileSigninSignupUrl, instance, activeAccount]
  );

  useEffect(() => {
    if (getPricingPlans) {
      getPricingPlans();
    }
  }, [getPricingPlans]);

  const freePlanId = "0000000-0000-0000-0000-000000000000";

  const activePlan = user?.activePlan;
  const activePricingPlan = pricingPlans?.find((x) => x.id === activePlan?.planId);
  const isActiveFreePlan = activePricingPlan?.id === freePlanId;
  const isHighestTier = activePricingPlan?.id === "0000000-0000-0000-0000-000000000002";

  return (
    <>
      <Helmet>
        <title>Compra - Pricing</title>
      </Helmet>
      <div className="bg-white h-full">
        <div className="relative h-full flex flex-col">
          <Navbar />
          <main>
            {/* Pricing section */}
            <div className="mx-auto mt-16 max-w-7xl px-6  lg:px-8">
              <div className="mx-auto max-w-4xl text-center">
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Pricing Plans</h1>
              </div>
              <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Start with the Free plan or upgrade to another plan for more checks and additional features.
              </p>
              <div className="mt-16 flex justify-center">
                <RadioGroup
                  value={frequency}
                  onChange={setFrequency}
                  className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                >
                  <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                  {pricing.frequencies.map((option) => (
                    <RadioGroup.Option
                      key={option.value}
                      value={option}
                      className={({ checked }) =>
                        classNames(
                          checked ? "bg-indigo-600 text-white" : "text-gray-500",
                          "cursor-pointer rounded-full px-2.5 py-1"
                        )
                      }
                    >
                      <span>{option.label}</span>
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </div>
              <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {pricingPlans?.map((plan: PlanEntity, tierIdx) => {
                  const isFreePlan = plan.id === freePlanId;
                  return (
                    <div
                      key={plan.name}
                      className={classNames(
                        plan.promoted ? "lg:z-10 lg:rounded-b-none ring-2 ring-indigo-600" : "lg:mt-8",
                        tierIdx === 0 ? "lg:rounded-r-none" : "",
                        tierIdx === pricingPlans.length - 1 ? "lg:rounded-l-none" : "",
                        plan.id === activePlan?.planId ? " ring-yellow-600 ring-2" : "",
                        "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
                      )}
                    >
                      <div className="flex items-center justify-between gap-x-4">
                        <h2
                          id={plan.name}
                          className={classNames(
                            plan.promoted ? "text-indigo-600" : "text-gray-900",
                            "text-lg font-semibold leading-8"
                          )}
                        >
                          {plan.name}
                        </h2>
                        {plan.promoted ? (
                          <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                            Most popular
                          </p>
                        ) : null}
                        {plan.id === activePlan?.planId ? (
                          <p className="rounded-full bg-yellow-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-yellow-600">
                            Current Plan
                          </p>
                        ) : null}
                      </div>
                      <p className="mt-4 text-sm leading-6 text-gray-600">{plan.description}</p>
                      <p className="mt-6 flex items-baseline gap-x-1">
                        {isFreePlan ? (
                          <span className="text-4xl font-bold tracking-tight text-gray-900">Free</span>
                        ) : (
                          <>
                            <span className="text-4xl font-bold tracking-tight text-gray-900">
                              ${frequency.value === "monthly" ? plan.costPerMonth : plan.costPerYear}
                            </span>
                            <span className="text-sm font-semibold leading-6 text-gray-600">
                              {frequency.priceSuffix}
                            </span>
                          </>
                        )}
                      </p>
                      <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                        {plan.features?.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                      <div className="flex-1 flex flex-col justify-end">
                        <UnauthenticatedTemplate>
                          <button
                            onClick={() => {
                              if (updateCart) {
                                updateCart((cart) => ({
                                  ...cart,
                                  subscriptions: [{ planId: plan.id, quantity: 1, duration: frequency.value }],
                                }));
                                handleLogin("/checkout");
                              }
                            }}
                            aria-describedby={plan.name}
                            className={classNames(
                              plan.promoted
                                ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                                : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                              "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            )}
                          >
                            {isActiveFreePlan ? "Try for Free" : "Buy Plan"}
                          </button>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                          {!isFreePlan && (
                            <button
                              onClick={() => {
                                if (plan.id === activePricingPlan?.id) {
                                  navigate("/cancel-plan");
                                } else if (updateCart) {
                                  updateCart((cart) => ({
                                    ...cart,
                                    subscriptions: [{ planId: plan.id, quantity: 1, duration: frequency.value }],
                                  }));
                                  navigate("/checkout");
                                }
                              }}
                              aria-describedby={plan.name}
                              className={classNames(
                                plan.promoted && plan.id !== activePricingPlan?.id && !isHighestTier
                                  ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                                  : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                                plan.id === activePlan?.planId
                                  ? "text-orange-300 hover:ring-yellow-200 ring-orange-200"
                                  : "",
                                "mt-12 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              )}
                            >
                              {plan.id === activePricingPlan?.id
                                ? "Cancel Plan"
                                : isActiveFreePlan
                                  ? "Buy Plan"
                                  : (activePricingPlan?.costPerMonth ?? 0) > plan.costPerMonth
                                    ? "Downgrade Plan"
                                    : "Upgrade Plan"}
                            </button>
                          )}
                        </AuthenticatedTemplate>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* FAQ section */}
            <div className="mx-auto my-24 max-w-7xl px-6 sm:my-56 lg:px-8">
              <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                  Frequently asked questions
                </h2>
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">{faq.question}</span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default PricingPage;
