import { ReactNode, useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

interface SmoothScrollProps {
    children: ReactNode;
}

const SmoothScroll = ({ children }: SmoothScrollProps) => {
    const location = useLocation();
    const navType = useNavigationType();
    useEffect(() => {
        if (navType !== "POP") {
            window.scrollTo({
                top: 0,
                behavior: "auto",
            });
        }
    }, [location, navType]);

    return <>{children}</>;
};
export default SmoothScroll;
