import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from "@heroicons/react/24/solid";

interface modalProps {
    open: boolean;
    setOpen: (b: boolean) => void;
    title: string;
    menu?: React.ReactNode;
    children?: React.ReactNode;
    onModalCloseAction?: () => void;
}

const Modal: FC<modalProps> = (props) => {
    const close = (b: boolean) => {
        props.onModalCloseAction && props.onModalCloseAction();
        props.setOpen(b);
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:px-8"
                        onClick={(event) => {
                            if (event.target === event.currentTarget) {
                                props.setOpen(false);
                            }
                        }}>
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            className="w-full max-w-5xl "
                        >
                            <div className="relative bg-white rounded-lg p-4 text-left shadow-xl transform transition-all sm:px-6 sm:py-4 md:px-8 min-h-[50vh]" >
                                <div className="flex flex-col">
                                    <div className="flex items-center">
                                        {props.menu &&
                                            <div className="mr-4">{props.menu}</div>
                                        }
                                        <div className="font-bold text-xl text-ellipsis truncate" title={props.title}>{props.title}</div>
                                        <XMarkIcon className="h-6 w-6 ml-auto cursor-pointer shrink-0" onClick={() => props.setOpen(false)} />
                                    </div>
                                    <div>{props.children}</div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
export default Modal;