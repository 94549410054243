import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import ConfettiExplosion from 'react-confetti-explosion';
import { Link } from "react-router-dom";
import { useGlobalContext } from "../providers/GlobalContext";
import { useEffect } from "react";


export default function PaymentSuccessPage() {
    const { updateCart } = useGlobalContext()

    //payment succeeded, so clear out cart
    useEffect(() => {
        if (updateCart) {
            updateCart((s) => ({ ...s, subscriptions: [], vouchers: [] }))
        }
    }, [updateCart])

    return (
        <>
            <Helmet>
                <title>Compra - Payment Success</title>
            </Helmet>
            <div className="bg-gray-50 h-full flex flex-col">
                <Navbar />
                <main className="flex-1 bg-gray-100">
                    <div className="max-w-md mx-auto py-16 px-4 sm:max-w-3xl sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:p-6 relative text-center">
                                <div className="block m-auto h-1 w-1">
                                    <ConfettiExplosion
                                        duration={3000}
                                        force={0.6}
                                        width={window.innerWidth}
                                        className=""
                                    />
                                </div>
                                <div className="block m-auto h-20 w-20">
                                    <CheckCircleIcon className="text-green-500" />
                                </div>
                                <h1 className="text-2xl leading-6 font-medium text-gray-900 mt-3">Payment Successful</h1>
                                <div className="mt-3 mb-6">Thank you for your payment, a reciept will be sent to your registered email.</div>

                                <Link to="/dashboard" className="">
                                    <button
                                        className="m-auto flex items-center p-2 px-8 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400"
                                    >
                                        Go to Dashboard
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}