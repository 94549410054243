import React from 'react';
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Navbar from '../components/navbar/Navbar';

function NotFoundPage() {

    return (
        <>
            <Helmet>
                <title>Compra - Page Not Found</title>
            </Helmet>
            <div className="bg-white h-full">
                <div className="relative overflow-hidden h-full flex flex-col">
                    <Navbar />
                    <main className="flex-1">


                        {/* Website Viewer */}
                        <div className="relative bg-gray-50 py-8 sm:py-12 lg:py-16 h-full">
                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                                <div>
                                    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                        Page Not Found!
                                    </p>
                                </div>
                                <div className="mt-10 sm:mt-12">
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default NotFoundPage;