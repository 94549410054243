import React, { useEffect } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { useGlobalContext } from "./providers/GlobalContext";
import { loginRequestRedirect } from "./config/authConfig";
export const axiosAuthenticated = axios.create();

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { instance, accounts } = useMsal();
  const { authSettings, setInterceptorLoaded, getPricingPlans, pricingPlans } = useGlobalContext();

  useEffect(() => {
    if (
      !!accounts.length &&
      !!instance &&
      authSettings?.scopes &&
      authSettings?.profileSigninSignupUrl &&
      setInterceptorLoaded
    ) {
      //set up interceptor for axios requests
      const interceptor = axiosAuthenticated.interceptors.request.use(
        (config: any) => {
          return instance
            .acquireTokenSilent({
              scopes: [authSettings.scopes],
              account: accounts[0],
            })
            .then((resp) => {
              //add header with refreshed token
              config.headers["Authorization"] = `Bearer ${resp.accessToken}`;
              return config;
            })
            .catch(function (error) {
              // Redirect to B2C if unable to renew token
              // due to consent or interaction required
              if (
                (error.errorCode === "consent_required" ||
                  error.errorCode === "interaction_required" ||
                  error.errorCode === "login_required") &&
                config.data.skipLoginRedirect !== true
              ) {
                const account = accounts[0];
                const claims: any = account.idTokenClaims;
                instance.loginRedirect(
                  loginRequestRedirect(
                    authSettings.profileSigninSignupUrl,
                    window.location.pathname,
                    undefined,
                    claims["email"]
                  )
                );
              } else {
                if (config.data.skipLoginRedirect === true) {
                  console.warn("Skipping login redirect...");
                  instance.logoutRedirect({ onRedirectNavigate: () => false });
                } else {
                  console.error(error);
                }
              }
            });
        },
        function (err) {
          return Promise.reject(err);
        }
      );
      setInterceptorLoaded(true);

      //clean up the interceptor
      return () => {
        axios.interceptors.request.eject(interceptor);
        setInterceptorLoaded(false);
      };
    }
  }, [accounts, instance, authSettings?.scopes, setInterceptorLoaded, authSettings?.profileSigninSignupUrl]);

  //load public page data
  useEffect(() => {
    if (pricingPlans && pricingPlans?.length === 0 && getPricingPlans) {
      getPricingPlans();
    }
  }, [getPricingPlans, pricingPlans]);

  return <div className="h-full">{children}</div>;
};
