import React, { FC } from 'react';
import Modal from "../modals/Modal";
import WebsiteViewer, { Job } from "./WebsiteViewer";

interface WebsiteViewerModalProps {
    open: boolean;
    setOpen: (b: boolean) => void;
    title: string;
    job?: Job;
    onJobCreationAction?: () => void;
    onModalCloseAction?: () => void;
}

const WebsiteViewerModal: FC<WebsiteViewerModalProps> = (props) => {
    return (
        <Modal
            open={props.open}
            setOpen={props.setOpen}
            title={props.title}
            onModalCloseAction={props.onModalCloseAction}
        >
            <WebsiteViewer
                job={props.job}
                onJobCreation={() => {
                    props.setOpen(false);
                    props.onJobCreationAction && props.onJobCreationAction();
                }}
                initialCrop={props.job?.crop}
            />
        </Modal>
    );
};

export default WebsiteViewerModal;