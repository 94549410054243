import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'overlayscrollbars/overlayscrollbars.css';
import 'react-image-crop/src/ReactCrop.scss';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequestRedirect, msalConfig } from "./config/authConfig";
import { AuthSettings } from "./providers/GlobalContext";

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href');
const root = ReactDOM.createRoot(
    document.getElementById('app') as HTMLElement
);


const fetchSettings = async () => {
    const response = await fetch('/api/auth');
    const authSettings: AuthSettings = await response.json();

    /**
     * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
     */
    const msalInstance = new PublicClientApplication(msalConfig(authSettings));
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    // Default to using the first account if no account is active on page load
    if (!activeAccount && accounts.length > 0) {
        //takes the last logged in account (according to auth_time)
        const account = accounts.reduce((prev, current) => ((prev.idTokenClaims as any).auth_time > (current.idTokenClaims as any).auth_time) ? prev : current);
        msalInstance.setActiveAccount(account);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    //handle id_token login
    msalInstance.handleRedirectPromise().then((ex) => {
        const hash = window.location.hash;
        if (msalInstance && hash && authSettings) {
            const params = new URLSearchParams(hash.replace('#', ''));
            const idToken = params.get('id_token') ?? undefined;
            const pageUrl = (new URLSearchParams(params.get("state") ?? "")).get("page_url") ?? window.location.pathname;
            if (idToken) {
                msalInstance.loginRedirect(loginRequestRedirect(authSettings.profileRedeemUrl, pageUrl, idToken));
            }
        }
    });

    root.render(
        <BrowserRouter basename={baseUrl ?? undefined}>
            <App instance={msalInstance} authSettings={authSettings} />
        </BrowserRouter>);
};

fetchSettings();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
