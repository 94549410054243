import * as React from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Job, Result } from "../components/websiteViewer/WebsiteViewer";
import { axiosAuthenticated } from "../Layout";
import axios from "axios";

export interface GlobalContextInterface {
  //auth settings
  authSettings?: AuthSettings;

  //user
  balance?: Balance;
  user?: User;
  getBalance?: (userId: string) => void;

  //pricing
  pricingPlans?: PlanEntity[];
  getPricingPlans?: () => void;
  pricingPlansLoading: boolean;

  //cart
  cart: Cart;
  updateCart?: React.Dispatch<React.SetStateAction<Cart>>;
  createCheckoutSession?: () => Promise<any>;
  cancelPlan?: (planId: string) => Promise<any>;

  //jobs
  getJobs?: (continuationToken?: string) => void;
  jobsLoading: boolean;
  jobs?: Job[];
  jobsPagingState?: JobsPagingState;
  clearJobs?: () => void;
  pauseJob?: (jobId: string) => void;
  unpauseJob?: (jobId: string) => void;
  deleteJob?: (jobId: string) => void;
  updateJob?: (job: Job) => void;
  jobUpdateLoading: boolean;

  //results
  getResults?: (jobId: string, continuationToken?: string) => void;
  resultsLoading: boolean;
  results?: Result[];
  resultsPagingState?: ResultsPagingState;
  clearResults?: () => void;

  //axios interceptor
  interceptorLoaded: boolean;
  setInterceptorLoaded?: (set: boolean) => void;
}

export interface PaymentIntentResponse {
  clientSecret: string;
}

export interface AuthSettings {
  clientId: string;
  profileSigninSignupUrl: string;
  profileRedeemUrl: string;
  scopes: string;
  stripePublicKey: string;
}

export interface UserResponse {
  userEntity: User;
  balance: Balance;
}

export interface Cart {
  subscriptions?: SubscriptionItem[];
  vouchers?: VoucherItem[];
  currency: string;
}
export interface SubscriptionItem {
  planId: string;
  quantity: number;
  duration: string;
}
export interface VoucherItem {
  voucherId: string;
  quantity: number;
}

export interface Balance {
  totalChecksRemaining: number;
  voucherChecksRemaining: number;
  planChecksRemaining: number;
}

export interface User {
  id: string;
  partitionKey: string;
  email: string;
  activePlan: Plan;
  plansHistory: Plan[];
  startDateUTC: Date;
  inactiveDateUTC: Date | null;
  stripeCustomerId: string;
  active: boolean;
  vouchers: Voucher[];
}

export interface Voucher {
  id: string;
  dateLoadedUTC: Date;
  expiryDateUTC: Date | null;
  amount: number;
  remaining: number;
  coupon: string | null;
  source: string | null;
}

export interface Plan {
  planId: string;
  active: boolean;
  startDateUTC: Date;
  endDateUTC: Date | null;
  stripeCheckoutId: string | null;
  stripeSubscriptionId: string | null;
  stripeCustomerId: string | null;
  invoices: Invoice[] | null;
  term: string;
  checksRemaining: number;
  nextTopUpDateUTC: Date;
}

export interface Invoice {
  stripeInvoiceId: string;
  startDateUTC: string;
  endDateUTC: string;
  amount: number;
  status: string;
  url: string;
  currency: string;
  paymentUrl: string;
}

export interface PlanEntity {
  id: string;
  name: string;
  features: string[];
  websiteOrder: number;
  promoted: boolean;
  isHidden: boolean;
  description: string;
  checksPerMonth: number;
  costPerMonth: number;
  costPerYear: number;
  createdDateUTC: Date;
  expiryUTC?: Date;
  currency: string;
}

export const GlobalContext = createContext<GlobalContextInterface>({
  jobs: [],
  results: [],
  pricingPlansLoading: false,
  interceptorLoaded: false,
  resultsLoading: false,
  jobsLoading: false,
  jobUpdateLoading: false,
  cart: { subscriptions: [], vouchers: [], currency: "" },
});

interface PagingState {
  continuationToken?: string;
  hasMoreResults?: boolean;
}
interface ResultsPagingState extends PagingState { }
interface JobsPagingState extends PagingState { }

interface GlobalProviderProps {
  children: React.ReactNode;
  authSettings: AuthSettings;
}

export const GlobalProvider = ({ children, authSettings }: GlobalProviderProps) => {
  const [interceptorLoaded, setInterceptorLoaded] = useState<boolean>(false);
  const intialCartJson = localStorage.getItem("cart");
  const [cart, setCart] = useState<Cart>(
    intialCartJson === null ? { subscriptions: [], vouchers: [], currency: "nzd" } : JSON.parse(intialCartJson)
  );

  const [pricingPlans, setPricingPlans] = useState<PlanEntity[]>([]);
  const [pricingPlansLoading, setLoadingPricingPlans] = useState<boolean>(false);

  const [jobs, setJobs] = useState<Job[]>([]);
  const [jobsPagingState, setJobsPagingState] = useState<JobsPagingState | undefined>(undefined);
  const [jobsLoading, setJobsLoading] = useState<boolean>(false);
  const [jobUpdateLoading, setJobUpdateLoading] = useState<boolean>(false);

  const [results, setResults] = useState<Result[]>();
  const [resultsPagingState, setResultsPagingState] = useState<ResultsPagingState | undefined>(undefined);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);

  const [user, setUser] = useState<User | undefined>(undefined);
  const [balance, setBalance] = useState<Balance | undefined>(undefined);

  useEffect(() => {
    //save cart to local storage on updates
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const getJobs = useCallback((continuationToken?: string) => {
    setJobsLoading(true);

    //get users jobs
    axiosAuthenticated
      .post("/api/jobs", { ContinuationToken: continuationToken })
      .then((ret) => {
        setJobs((results) => {
          const oldResults = results?.filter((r) => !ret.data.results.some((newR: Result) => newR.id === r.id)) ?? [];
          return oldResults.concat(ret.data.results);
        });
        setJobsPagingState({ continuationToken: ret.data.continuationToken, hasMoreResults: ret.data.hasMoreResults });
      })
      .finally(() => {
        setJobsLoading(false);
      });
  }, []);

  const getBalance = useCallback((userId: string) => {
    setJobsLoading(true);

    //get users jobs
    axiosAuthenticated
      .get<UserResponse>(`/api/user/${userId}/balance`, { data: { skipLoginRedirect: true } })
      .then((ret) => {
        const userResp = ret.data;
        setUser(userResp.userEntity);
        setBalance(userResp.balance);
      });
  }, []);

  const getPricingPlans = useCallback(() => {
    setLoadingPricingPlans(true);

    axios
      .get<PlanEntity[]>("/api/billing/plans")
      .then((ret) => {
        const pricingPlans = ret.data;
        setPricingPlans(pricingPlans);
      })
      .finally(() => {
        setLoadingPricingPlans(false);
      });
  }, []);

  const updateJob = useCallback((job: Job) => {
    setJobUpdateLoading(true);

    //get users jobs
    axiosAuthenticated
      .patch<Job>(`/api/jobs/${job.id}`, { job: job })
      .then((ret) => {
        const job = ret.data;

        setJobs((results) => {
          return [...results.filter((o) => o.id !== job.id), { ...job }];
        });
      })
      .finally(() => {
        setJobUpdateLoading(false);
      });
  }, []);

  const clearJobs = useCallback(() => {
    setJobs([]);
    setJobsPagingState(undefined);
  }, []);

  const getResults = useCallback((jobId: string, continuationToken?: string) => {
    setResultsLoading(true);

    //get users job results
    axiosAuthenticated
      .post(`/api/jobs/${jobId}/results`, { ContinuationToken: continuationToken })
      .then((ret) => {
        setResults((results) => {
          const oldResults = results?.filter((r) => !ret.data.results.some((newR: Result) => newR.id === r.id)) ?? [];
          return oldResults.concat(ret.data.results);
        });
        setResultsPagingState({
          continuationToken: ret.data.continuationToken,
          hasMoreResults: ret.data.hasMoreResults,
        });
      })
      .finally(() => {
        setResultsLoading(false);
      });
  }, []);

  const clearResults = useCallback(() => {
    setResults([]);
    setResultsPagingState(undefined);
  }, []);

  const pauseJob = useCallback((jobId: string) => {
    //get users jobs
    axiosAuthenticated.get<Job>(`/api/jobs/${jobId}/pause`).then((ret) => {
      const job = ret.data;
      setJobs((jb) => jb.map((j) => (j.id === jobId ? job : j)));
    });
  }, []);

  const unpauseJob = useCallback((jobId: string) => {
    //get users jobs
    axiosAuthenticated.get<Job>(`/api/jobs/${jobId}/unpause`).then((ret) => {
      const job = ret.data;
      setJobs((jb) => jb.map((j) => (j.id === jobId ? job : j)));
    });
  }, []);

  const deleteJob = useCallback((jobId: string) => {
    //get users jobs
    axiosAuthenticated.get(`/api/jobs/${jobId}/delete`).then(() => {
      setJobs((jobs) => {
        jobs = jobs.filter((x) => x.id !== jobId);
        return jobs;
      });
    });
  }, []);

  const createCheckoutSession = useCallback(() => {
    return axiosAuthenticated.post(`/api/billing/checkoutSession`, cart);
  }, [cart]);

  const cancelPlan = useCallback((planId: string) => {
    return axiosAuthenticated.get(`/api/billing/cancelPlan/${planId}`);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        authSettings: authSettings,

        cart: cart,
        updateCart: setCart,
        createCheckoutSession: createCheckoutSession,

        cancelPlan: cancelPlan,

        jobs: jobs,
        getJobs: getJobs,
        jobsLoading: jobsLoading,
        jobsPagingState: jobsPagingState,
        clearJobs: clearJobs,
        pauseJob: pauseJob,
        unpauseJob: unpauseJob,
        deleteJob: deleteJob,
        updateJob: updateJob,
        jobUpdateLoading: jobUpdateLoading,

        pricingPlansLoading: pricingPlansLoading,
        getPricingPlans: getPricingPlans,
        pricingPlans: pricingPlans,

        getBalance: getBalance,
        balance: balance,
        user: user,

        results: results,
        getResults: getResults,
        resultsLoading: resultsLoading,
        resultsPagingState: resultsPagingState,
        clearResults: clearResults,

        setInterceptorLoaded: setInterceptorLoaded,
        interceptorLoaded: interceptorLoaded,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
