import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ReactCrop, { Crop } from 'react-image-crop';
import * as Scroll from 'react-scroll';
import SkeletonUI from '../skeletonUI/SkeletonUI';
import { usePrevious } from '../../utils/CustomHooks';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FrequencySelector, { frequencies, FrequencyItem } from "../frequencySelector/FrequencySelector";
import { axiosAuthenticated } from "../../Layout";
import { useGlobalContext } from "../../providers/GlobalContext";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

interface WebsiteViewerProps {
    job?: Job;
    onJobCreation?: () => void;
    scrollOnSubmit?: boolean;
    initialCrop?: Crop;
}
const axiosCancelToken = axios.CancelToken;

export interface Job {
    id: string;
    email: string;
    crop: Crop;
    fixedCrop?: Crop;
    userId: string;
    active: boolean;
    frequencyInMinutes: number;
    url: string;
    lastCheckDateTimeUTC: Date;
    creationDateTimeUTC: Date;
    lastUpdatedDateTimeUTC: Date;
    differenceThreshold: number;
    jobName: string;
    initialImageUrl: string;
    nextOccurenceDateTimeUTC: Date;
    lastImageBlobUrl: string;
    timeZone: string;
    closePopups: boolean;
}

export interface Result {
    diffExceededThreshold: boolean;
    diffImageBlobUrl: string;
    id: string;
    imageBlobUrl: string;
    jobId: string;
    partitionKey: string;
    predictedChange: number;
    timeCheckedDateTimeUTC: Date;
    firstCheck: boolean;
    cropUpdate: boolean;
}


export interface MonitorImageResponse {
    base64Image: string;
    pageTitle: string;
}
const defaultCrop: Crop = {
    x: 10,
    y: 10,
    width: 50,
    height: 25,
    unit: '%'
};

const WebsiteViewer: FC<WebsiteViewerProps> = (props) => {
    const navigate = useNavigate();
    const { updateJob } = useGlobalContext();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const isAuthenticated = useIsAuthenticated();

    const existingJob = props.job;
    const onJobCreation = props.onJobCreation;

    const [url, setUrl] = useState("");
    const [errorUrl, setErrorUrl] = useState("");
    const [image, setImage] = useState("");
    const [frequency, setFrequency] = useState<FrequencyItem>(frequencies[0]);
    const [isUrlLoading, setUrlIsLoading] = useState(false);
    const [isJobLoading, setJobIsLoading] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors }, getValues } = useForm<Job>({
        defaultValues: {
            closePopups: false
        }
    });

    const [crop, setCrop] = useState<Crop>(props.initialCrop ?? defaultCrop);
    const prevUrl = usePrevious(url);

    useEffect(() => {
        if (activeAccount) {
            const claims: any = activeAccount.idTokenClaims;
            if (claims.email) {
                setValue('email', claims.email);
            }
        }
    }, [activeAccount, setValue]);


    const urlSubmitCancellationToken = useRef<CancelTokenSource | null>(null);
    const jobSubmitCancellationToken = useRef<CancelTokenSource | null>(null);

    const CancelUrlSubmitToken = (canellationReason?: string) => {
        if (urlSubmitCancellationToken.current) {
            urlSubmitCancellationToken.current.cancel(canellationReason);
            setUrlIsLoading(false);
        }
    };

    const CancelJobSubmitToken = (canellationReason?: string) => {
        if (jobSubmitCancellationToken.current) {
            jobSubmitCancellationToken.current.cancel(canellationReason);
            setJobIsLoading(false);
        }
    };


    const handleUrlSubmit = useCallback(async (prevUrl: string, overrideUrl?: string) => {
        //don't process the same url
        if (((prevUrl === url && isUrlLoading) || !url) && !overrideUrl) {
            return;
        }
        setErrorUrl("");

        if (!overrideUrl && !!props.scrollOnSubmit) {
            //scrolls to 
            Scroll.scroller.scrollTo('url', {
                duration: 500,
                smooth: true,
                offset: -30,
            });
        }


        //cancel existing token
        CancelUrlSubmitToken();

        //sets the axios token
        urlSubmitCancellationToken.current = axiosCancelToken.source();

        // update state
        setUrlIsLoading(true);
        const closePopups = getValues("closePopups") ?? true;

        // send the actual request
        axios.get('/api/monitor/image', {
            cancelToken: urlSubmitCancellationToken.current.token,
            params: {
                url: overrideUrl ?? url,
                closePopups: closePopups
            }
        }).then((response) => {
            const data: MonitorImageResponse = response.data;
            setImage(data.base64Image);
            setValue('jobName', data.pageTitle);
            setValue('url', url);
            setUrlIsLoading(false);
            setErrorUrl("");
        }).catch((error: Error | AxiosError) => {
            if (!axios.isCancel(error)) {
                setUrlIsLoading(false);
            }
            if (axios.isAxiosError(error)) {
                const errorResp = error.response?.data as string;
                if (errorResp.includes("URL_NOT_EXIST")) {
                    setErrorUrl("The url doesn't appear to be valid. Please check it and try again!");
                }
                else {
                    setErrorUrl("Something has gone wrong...");
                }
            } else {
                setErrorUrl("Something has gone wrong...");
            }
            setImage("");
        });
    }, [url, isUrlLoading, setValue, props.scrollOnSubmit, getValues]);

    const handleJobSubmit = useCallback(async (data: Job) => {
        if (isJobLoading) {
            return;
        }

        //cancel existing token
        CancelJobSubmitToken();

        //sets the axios token
        jobSubmitCancellationToken.current = axiosCancelToken.source();

        // update state
        setJobIsLoading(true);
        const createJobRequest = ({
            crop: crop ? crop : {
                x: 0,
                y: 0,
                width: 100,
                height: 100,
                unit: '%'
            },
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            closePopups: data.closePopups,
            frequencyInMinutes: frequency.value,
            url: url,
            email: data.email,
            jobName: data.jobName
        });

        if (existingJob && updateJob) {
            updateJob({ ...existingJob, crop: crop });
            setJobIsLoading(false);
            onJobCreation && onJobCreation();
        }
        else {
            const axiosInstance = isAuthenticated ? axiosAuthenticated : axios;

            // send the actual request
            axiosInstance.post('/api/jobs/create', createJobRequest, {}).then((response) => {
                if (isAuthenticated) {
                    navigate({ pathname: '/dashboard' }, { replace: true });
                }
                else {
                    navigate({ pathname: '/job-created' }, { replace: true });
                }
            }).finally(() => {
                setJobIsLoading(false);
                onJobCreation && onJobCreation();
            });
        }

    }, [crop, url, navigate, frequency, isJobLoading, existingJob, updateJob, onJobCreation, isAuthenticated]);

    useEffect(() => {
        if (existingJob) {
            setUrl(existingJob.url);
            handleUrlSubmit("", existingJob.url);
        }

        // TODO: solve this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingJob]);

    return (
        <div>
            <div className="">
                <div className="">
                    <div className="sm:flex gap-2">
                        <div className="min-w-0 flex-1">
                            <label htmlFor="url" className="flex text-slate-600 text-sm font-semibold mb-1">
                                Website to Monitor
                            </label>
                            <input
                                id="url"
                                type="text"
                                placeholder="Enter website url:  www.google.com"
                                className={"inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-3 bg-white text-base rounded-md placeholder-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none" + (errorUrl ? " focus:ring-red-500 focus:border-red-500 border-red-300" : " focus:ring-cyan-500 focus:border-cyan-500 border-gray-300")}
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                disabled={!!existingJob}
                                onKeyPress={(e) => e.key === "Enter" && handleUrlSubmit(prevUrl)}
                            />
                        </div>
                        {
                            !existingJob &&
                            <div className="mt-3 sm:mt-0 flex flex-col">
                                <button
                                    onClick={() => handleUrlSubmit(prevUrl)}
                                    className="mt-6 flex-1 block w-full p-3 px-8 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400"
                                >
                                    Compare
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                (image || isUrlLoading) &&
                <OverlayScrollbarsComponent
                    id="webpage-preview"
                    className={"mt-8 os-theme-light rounded-sm md:rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 max-h-[80vh]"}
                    options={{
                        overflow: {
                            x: "scroll",
                            y: (isUrlLoading ? "hidden" : "scroll")
                        },
                        scrollbars: {
                            autoHide: 'never'
                        }
                    }}
                >
                    <div className="flex flex-col">
                        {
                            isUrlLoading ?
                                <SkeletonUI className="w-full h-full p-4"
                                    speed={2}
                                    width={1280}
                                    height={900}
                                    viewBox="0 0 1280 900"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                />
                                :
                                <ReactCrop
                                    crop={crop}
                                    onChange={(newCrop, percentCrop) => setCrop(percentCrop)}
                                >
                                    <img
                                        src={`data:image/jpeg;base64,${image}`}
                                        alt="website snapshot"
                                    />
                                </ReactCrop>

                        }
                    </div>
                </OverlayScrollbarsComponent>
            }
            {
                errorUrl &&
                <div className="text-red-700 italic p-3">{errorUrl}</div>
            }
            {
                (image && !isUrlLoading) &&
                <form onSubmit={handleSubmit(handleJobSubmit)}>
                    <div className="mt-4 sm:mt-8">
                        <div className="">
                            <div className="sm:flex gap-2">
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="email" className="flex text-slate-600 text-sm font-semibold mb-1">
                                        Email
                                    </label>
                                    <input
                                        {...register("email", { required: true })}
                                        placeholder="email@example.com"
                                        className={"inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-3 bg-white text-base rounded-md placeholder-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 border-gray-300" + (errors.email ? " border-red-300" : "")}
                                    />
                                    {errors.email?.type === 'required' && <div className="text-red-700 italic text-left py-2">Email is required!</div>}
                                </div>
                                <div className="min-w-0 mt-4 sm:m-0">
                                    <label htmlFor="frequency" className="flex text-slate-600 text-sm font-semibold mb-1">
                                        Frequency
                                    </label>
                                    <FrequencySelector setFrequency={setFrequency} frequency={frequency} className="" />
                                </div>
                            </div>
                            <div className="sm:flex mt-4">
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="email" className="flex text-slate-600 text-sm font-semibold mb-1">
                                        Job Name
                                    </label>
                                    <input
                                        {...register("jobName")}
                                        className="inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-3 bg-white text-base rounded-md placeholder-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 border-gray-300"
                                    />
                                </div>
                            </div>
                            <div className="relative flex items-start py-4">
                                <div className="min-w-0 text-sm leading-6">
                                    <label htmlFor={"closePopups"} className="select-none font-medium text-gray-900">
                                        Attempt to close popups
                                    </label>
                                </div>
                                <div className="ml-3 flex h-6 items-center">
                                    <input
                                        type="checkbox"
                                        {...register("closePopups")}
                                        onChange={(e) => {
                                            setValue("closePopups", e.target.checked);
                                            handleUrlSubmit(url);
                                        }}
                                        className="h-6 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                            </div>
                            <div className="mt-6">
                                <button
                                    className="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 disabled:grayscale-[60%] disabled:hover:from-teal-500 disabled:hover:to-cyan-600"
                                    disabled={isJobLoading}
                                >Start Monitoring</button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    );
};

export default WebsiteViewer;