import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { Helmet } from "react-helmet-async";

const contactDetails = [
    { name: 'For General Enquiries', email: 'info@compra.co.nz' },
    { name: 'For Support Enquiries', email: 'support@compra.co.nz' },
];
const locations = [
    { city: 'New Zealand', address: ['Hamilton', 'Waikato 3200'] },
];

export default function ContactPage() {
    return (
        <>
            <Helmet>
                <title>Compra - Contact</title>
            </Helmet>
            <div className="bg-white h-full flex flex-col">
                <Navbar />
                <main className="flex-1">
                    {/* Side-by-side grid */}
                    <div className="bg-white">
                        <div className="mx-auto mt-16 max-w-7xl px-6  lg:px-8">
                            <div className="mx-auto max-w-4xl text-center">
                                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                    Contact Us
                                </h1>
                            </div>
                        </div>
                        <div className="max-w-md mx-auto py-24 px-4 sm:max-w-3xl sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="divide-y divide-warm-gray-200">
                                <section className="lg:grid lg:grid-cols-3 lg:gap-8" aria-labelledby="contact-heading">
                                    <h2 id="contact-heading" className="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl">
                                        Get in touch
                                    </h2>
                                    <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                                        {contactDetails.map((item) => (
                                            <div key={item.name}>
                                                <h3 className="text-lg font-medium text-warm-gray-900">{item.name}</h3>
                                                <dl className="mt-2 text-base text-warm-gray-500">
                                                    <div>
                                                        <dt className="sr-only">Email</dt>
                                                        <dd>
                                                            <a className="text-blue-400 hover:text-blue-300" href={"mailto:" + item.email}>{item.email}</a>
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                                <section className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8" aria-labelledby="location-heading">
                                    <h2 id="location-heading" className="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl">
                                        Locations
                                    </h2>
                                    <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                                        {locations.map((location) => (
                                            <div key={location.city}>
                                                <h3 className="text-lg font-medium text-warm-gray-900">{location.city}</h3>
                                                <div className="mt-2 text-base text-warm-gray-500 space-y-1">
                                                    {location.address.map((line) => (
                                                        <p key={line}>{line}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                    {/* FAQ */}
                    <div className="bg-warm-gray-50">
                        <div className="max-w-md mx-auto py-24 px-4 sm:max-w-3xl sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                                <div>
                                    <h2 className="text-3xl font-extrabold text-warm-gray-900">Frequently asked questions</h2>
                                    <p className="mt-4 text-lg text-warm-gray-500">
                                        Can't find the answer you're looking for? Reach out to our{' '}
                                        <a className="font-medium text-blue-400 hover:text-blue-300" href="mailto:info@compra.co.nz">customer support</a>
                                        {' '}
                                        team.
                                    </p>
                                </div>
                                <div className="mt-12 lg:mt-0 lg:col-span-2">
                                    <dl className="space-y-12">
                                        <div>
                                            <dt className="text-lg font-medium text-warm-gray-900">The URL that I entered isn't displaying as expected?</dt>
                                            <dd className="mt-2 text-base text-warm-gray-500">Please send an email to <a className="font-medium text-blue-400 hover:text-blue-300" href="mailto:support@compra.co.nz">support@compra.co.nz</a> and let us know the issue and we will do our best to fix it!</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}