import React, { Fragment, useCallback, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useGlobalContext } from "../../providers/GlobalContext";
import { loginRequestRedirect } from "../../config/authConfig";
import { LogoDark, LogoLight } from "../../images/index";

const navigation = [
  { name: "Monitor", href: "/" },
  { name: "Plans", href: "/plans" },
  { name: "Contact", href: "/contact" },
];

function Navbar() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const { authSettings, getBalance, balance, interceptorLoaded } = useGlobalContext();
  const activeAccount = instance.getActiveAccount();

  const handleLogin = useCallback(() => {
    if (authSettings?.profileSigninSignupUrl) {
      const email: any = activeAccount?.idTokenClaims?.email ?? undefined;
      instance
        .loginRedirect(loginRequestRedirect(authSettings.profileSigninSignupUrl, location.pathname, undefined, email))
        .catch((e) => {
          console.error(e);
        });
    }
  }, [authSettings?.profileSigninSignupUrl, instance, activeAccount, location.pathname]);

  useEffect(() => {
    const objectId: any = activeAccount?.idTokenClaims?.sub ?? undefined;
    if (objectId && getBalance && interceptorLoaded) {
      getBalance(objectId);
    }
  }, [getBalance, interceptorLoaded, activeAccount?.idTokenClaims?.sub]);

  const handleLogout = () => {
    instance.logoutRedirect({ account: activeAccount }).catch((e) => {
      console.error(e);
    });
  };

  const handleJumpToMonitor = () => {
    navigate("/");
  };

  return (
    <Popover as="header" className="relative">
      <div className="bg-gray-900 py-4">
        <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/">
                <span className="sr-only">Workflow</span>
                <LogoDark className="h-8 w-auto sm:h-10" />
              </Link>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden space-x-8 md:flex md:ml-10">
              {navigation.map((item) => (
                <Link key={item.name} to={item.href} className="text-base font-medium text-white hover:text-gray-300">
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-6">
            {!!balance && (
              <div
                className={
                  "text-white flex justify-center items-center border-2 p-2 rounded-md space-x-2" +
                  (balance.totalChecksRemaining === 0 ? " border-red-500" : " border-green-500")
                }
              >
                <span className="">{balance.totalChecksRemaining}</span>
                <span className="">Checks</span>
                <span
                  className={
                    "w-2 h-2 block rounded" + (balance.totalChecksRemaining === 0 ? " bg-red-500" : " bg-green-500")
                  }
                ></span>
              </div>
            )}
            <AuthenticatedTemplate>
              <Link
                to="/dashboard"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                Dashboard
              </Link>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <button
                onClick={handleJumpToMonitor}
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                Try for free
              </button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <button onClick={handleLogout} className="text-base font-medium text-white hover:text-gray-300">
                Log out
              </button>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <button onClick={handleLogin} className="text-base font-medium text-white hover:text-gray-300">
                Log in
              </button>
            </UnauthenticatedTemplate>
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <LogoLight className="h-8 w-auto" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="pt-5 pb-6">
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="mt-6 px-5">
                <AuthenticatedTemplate>
                  <Link
                    to="/dashboard"
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700"
                  >
                    Dashboard
                  </Link>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                  <button
                    onClick={handleJumpToMonitor}
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700"
                  >
                    Try for free
                  </button>
                </UnauthenticatedTemplate>
              </div>
              <div className="mt-6 px-5">
                <p className="text-center text-base font-medium text-gray-500">
                  <AuthenticatedTemplate>
                    <button onClick={handleLogout} className="text-gray-900 hover:underline">
                      Log out
                    </button>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <>
                      Existing customer?{" "}
                      <button onClick={handleLogin} className="text-gray-900 hover:underline">
                        Log in
                      </button>
                    </>
                  </UnauthenticatedTemplate>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default Navbar;
