import React from 'react';
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Navbar from '../components/navbar/Navbar';
import WebsiteViewer from '../components/websiteViewer/WebsiteViewer';

function HomePage() {

    return (
        <>
            <Helmet>
                <title>Compra - Monitor</title>
            </Helmet>
            <div className="bg-white">
                <div className="relative overflow-hidden">
                    <Navbar />
                    <main>
                        <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                            <div className="mx-auto max-w-7xl lg:px-8">
                                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                                    <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                                        <div className="lg:py-24">
                                            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-6xl xl:text-6xl">
                                                <span className="block">A better way to</span>
                                                <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400 sm:pb-5">
                                                    monitor web pages
                                                </span>
                                            </h1>
                                            <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                                                Set up an alert and get notified whenever it changes
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                                            {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                                            <img
                                                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                                src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Website Viewer */}
                        <div className="relative bg-gray-50 py-8 sm:py-12 lg:py-16">
                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
                                <div>
                                    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                        Set up your alert
                                    </p>
                                    <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                                        Start by choosing a website to monitor for changes. Then choose which part of the website is important.
                                    </p>
                                </div>
                                <div className="mt-10 sm:mt-12">
                                    <WebsiteViewer scrollOnSubmit />
                                </div>
                            </div>
                        </div>

                        {/* CTA Section */}
                        {/*<div className="relative bg-gray-900">*/}
                        {/*    <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">*/}
                        {/*        <img*/}
                        {/*            className="w-full h-full object-cover"*/}
                        {/*            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"*/}
                        {/*            alt=""*/}
                        {/*        />*/}
                        {/*        <div*/}
                        {/*            aria-hidden="true"*/}
                        {/*            className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">*/}
                        {/*        <div className="md:ml-auto md:w-1/2 md:pl-10">*/}
                        {/*            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">*/}
                        {/*                Award winning support*/}
                        {/*            </h2>*/}
                        {/*            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">We're here to help</p>*/}
                        {/*            <p className="mt-3 text-lg text-gray-300">*/}
                        {/*                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a*/}
                        {/*                scelerisque amet ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat quisque ut interdum*/}
                        {/*                tincidunt duis.*/}
                        {/*            </p>*/}
                        {/*            <div className="mt-8">*/}
                        {/*                <div className="inline-flex rounded-md shadow">*/}
                        {/*                    <a*/}
                        {/*                        href="/"*/}
                        {/*                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"*/}
                        {/*                    >*/}
                        {/*                        Visit the help center*/}
                        {/*                        <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                        {/*                    </a>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default HomePage;