import React from 'react';
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Navbar from '../components/navbar/Navbar';

function TermsPage() {

    return (
        <>
            <Helmet>
                <title>Compra - Terms</title>
            </Helmet>
            <div className="bg-white h-full">
                <div className="relative h-full flex flex-col">
                    <Navbar />
                    <main className="flex-1">
                        <div className="relative bg-gray-50 py-8 sm:py-12 lg:py-16 h-full">
                            <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-4xl">
                                <div>
                                    <h1 className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                                        Terms of Use
                                    </h1>
                                    <div className="mt-3 sm:mt-12 ">
                                        <div className="text-left mb-16">
                                            <p className="text-gray-500 mb-4">Welcome to Compra! We are a web-based service that helps users track changes to websites over time (collectively, the "Services"). This website (the "Site") is provided by Compra Ltd., a company registered under applicable laws.</p>
                                            <p className="text-gray-500 mb-4">These Terms of Use (these "Terms") govern your access to and use of the Site and the Services provided by Compra. PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SITE OR THE SERVICES. BY ACCESSING THE SITE, CREATING AN ACCOUNT AND/OR USING THE SERVICES, YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS DESCRIBED HEREIN. IF YOU DO NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS, THEN YOU MAY NOT USE THE SITE OR THE SERVICES. COMPRA'S ACCEPTANCE OF YOUR USE OF THE SITE AND/OR THE SERVICES IS EXPRESSLY CONDITIONED UPON YOUR ASSENT TO ALL OF THE TERMS AND CONDITIONS DESCRIBED HEREIN.</p>
                                        </div>
                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">1. Eligibility and Registration Data</h2>
                                            <p className="text-gray-500 mb-4">You must be 18 years of age or older to use the Services. To use certain features of the Services, you must register for an account. By registering for an account, you represent and warrant that any information that you submit is true and accurate, that you are 18 years of age or older and are fully able and competent to enter into, and abide by these Terms. You further agree to (i) provide accurate, current and complete information about you as may be prompted by any signup, login and/or registration forms within our registration process ("Registration Data"); (ii) maintain the security of your password and identification; (iii) maintain and promptly update the Registration Data, and any other information you provide to us, to keep it accurate, current and complete; (iv) accept all risks of unauthorized access to the Registration Data and any other information you provide to us; and (v) notify us immediately of any unauthorized use of your account or any other breach of security by emailing us at <a className="text-blue-400 hover:text-blue-300" href="mailto:info@compra.co.nz">info@compra.co.nz</a>. Use of the Services is void where prohibited.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">2. Site Transactions</h2>
                                            <p className="text-gray-500 mb-4">The Site allows users to make transactions. Data will be securely stored in our site or in third party sites when a transaction is made, including the date and type of purchase made, your credit card number, expiration date, billing address and CW or security code number.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">3. Your Control</h2>
                                            <p className="text-gray-500 mb-4">You can choose not to provide us with certain information, but that may result in you being unable to use certain features of the Site and the Services because such information may be required in order for you to register as a member; purchase products or services; participate in a survey; ask a question; or initiate other transactions on the Site or through the Services.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">4. Information Retention and Disposal</h2>
                                            <p className="text-gray-500 mb-4">We may retain personal information for as long as we have a business need for it or as applicable laws or regulations or government orders require us to retain it. Additionally, the Site and/or the Services may enable you (or other users) to save information including information that you have made public or allowed certain users to access or see.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">5. What Choices Do You Have Regarding the Use of Your Information?</h2>
                                            <p className="text-gray-500 mb-4">You may "opt out" of receiving marketing or promotional emails from us by following the instructions in those emails or by emailing us at <a className="text-blue-400 hover:text-blue-300" href="mailto:info@compra.co.nz">info@compra.co.nz</a>. If you opt out, we may still send you non-promotional emails, such as emails about your accounts or our ongoing business relations.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">6. Modifications to the Services</h2>
                                            <p className="text-gray-500 mb-4">The Services that Compra provides are always evolving and the form and nature of the Services that Compra provides may change from time to time without prior notice to you. In addition, Compra may stop (permanently or temporarily) providing the Site and/or the Services (or any features within the Site and/or the Services) to you or to users generally and may not be able to provide you with prior notice.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">7. Term and Termination</h2>
                                            <p className="text-gray-500 mb-4">Your account will remain in effect until the earlier of your cancellation or our termination of your account in accordance with these Terms. To cancel your account, please email us at <a className="text-blue-400 hover:text-blue-300" href="mailto:info@compra.co.nz">info@compra.co.nz</a>. Notwithstanding anything to the contrary herein, we have the right, without notice and in our sole discretion, to terminate your license to use the Site and/or the Services, and to block or prevent your future access to and use of the Site and/or the Services.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">8. Applicable Law and Venue</h2>
                                            <p className="text-gray-500 mb-4">These Terms and your use of the Site and/or the Services will be governed by and construed in accordance with the laws of the location where your company is registered, applicable to agreements made and to be entirely performed within that location, without resort to its conflict of law provisions. You agree that any action at law or in equity arising out of or relating to these Terms or your use of the Site and/or the Services will be filed only in the state and federal courts located in your area and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or proceeding arising out of these Terms and/or your use of the Site and/or the Services.</p>
                                        </div>
                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">9. Privacy Policy</h2>
                                            <p className="text-gray-500 mb-4">Please refer to our Privacy Policy, which also applies to your access and use of the Site and the Services, and describes our practices and policies related to the collection, use and storage of information about users of the Site and/or the Services.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">10. Consent to Electronic Communications</h2>
                                            <p className="text-gray-500 mb-4">By accessing the Site and/or using the Services, you consent to receiving electronic communications from us. Such communications may include notices about your account and information concerning or relating to the Services. You agree that any electronic notices, agreements, disclosures or other communications will satisfy any legal communication requirements, including any requirements that such communications be in writing.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">11. Entire Agreement</h2>
                                            <p className="text-gray-500 mb-4">These Terms, including all Policies, constitute the entire agreement between Compra and you concerning the Site and the Services. These Terms supersede any and all prior agreements or communications between Compra and you concerning the subject matter of these Terms.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">12. Questions & Contact Information</h2>
                                            <p className="text-gray-500 mb-4">Questions, comments and any other correspondence regarding our site may be directed to us at the email address <a className="text-blue-400 hover:text-blue-300" href="mailto:info@compra.co.nz">info@compra.co.nz</a>.</p>
                                        </div>
                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">13. Intellectual Property</h2>
                                            <p className="text-gray-500 mb-4">All content included on this Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Compra or its content suppliers and protected by international copyright laws.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">14. Dispute Resolution</h2>
                                            <p className="text-gray-500 mb-4">If a dispute arises between you and Compra, our goal is to provide you with a neutral and cost-effective means of resolving the dispute quickly. Accordingly, you and Compra agree that any claim or controversy at law or equity that arises out of this Agreement or our services ("Claims") will be resolved in accordance with one of the subsections below or as otherwise mutually agreed upon in writing by the parties.</p>
                                        </div>

                                        <div className="text-left mb-16">
                                            <h2 className="font-bold text-3xl mb-6 text-center">15. Limitation of Liability</h2>
                                            <p className="text-gray-500 mb-4">In no event shall Compra, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default TermsPage;