import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Navbar from '../components/navbar/Navbar';
import { useIsAuthenticated } from "@azure/msal-react";
import { useGlobalContext } from '../providers/GlobalContext';
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Job } from "../components/websiteViewer/WebsiteViewer";
import { SpinnerCircular } from "spinners-react";
import JobDetails from "../components/JobDetails/JobDetails";
import JobMenu from "../components/menu/JobMenu";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import WebsiteViewerModal from "../components/websiteViewer/WebsiteViewerModal";
import { frequencies } from "../components/frequencySelector/FrequencySelector";

dayjs.extend(relativeTime);

function DashboardPage() {
    const isAuthenticated = useIsAuthenticated();
    const { jobs, getJobs, interceptorLoaded, clearResults, jobsLoading } = useGlobalContext();
    const [jobSearch, setJobSearch] = useState("");
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [jobDetailsOpen, setJobDetailsOpen] = useState(false);
    const [jobAddOpen, setJobAddOpen] = useState(false);

    //filters the jobs based on the search
    const filteredJobs = jobs
        ?.filter(x =>
            jobSearch === "" ||
            x.jobName.toLowerCase().includes(jobSearch.toLowerCase()) ||
            x.url.toLowerCase().includes(jobSearch.toLowerCase())
        )
        ?? [];

    //load jobs
    useEffect(() => {
        const jobCheck = () => {
            if (isAuthenticated && interceptorLoaded && !!getJobs) {
                getJobs();
            }
        };
        const interval = setInterval(() => {
            jobCheck();
        }, 30000);

        jobCheck();
        return () => clearInterval(interval);
    }, [isAuthenticated, getJobs, interceptorLoaded]);

    //Open selected job details
    useEffect(() => {
        if (!selectedJob) {
            clearResults && clearResults();
        }
    }, [selectedJob, clearResults]);

    const deleteJob = () => {
        setJobDetailsOpen(false);
        setJobAddOpen(false);
        setSelectedJob(null);
    };

    return (
        <>
            <Helmet>
                <title>Compra - Dashboard</title>
            </Helmet>
            <div className="bg-neutral-100 h-full flex flex-col">
                <Navbar />
                {/* Main content */}
                <div className="flex-1 flex items-stretch overflow-hidden p-8 gap-x-6">
                    <main className="flex-1 overflow-y-auto bg-white shadow overflow-hidden sm:rounded-lg h-full">
                        {/* Primary column */}
                        <section aria-labelledby="primary-heading" className="min-w-0 flex-1 h-full flex flex-col lg:order-last h-full">
                            <h1 id="primary-heading" className="sr-only">
                                Dashboard
                            </h1>

                            <div className="">
                                <div className="px-4 py-5 sm:px-6">
                                    <div className="flex">
                                        <h1 className="text-3xl leading-6 font-medium text-gray-900">Jobs</h1>
                                        <button
                                            className="ml-auto flex items-center p-2 px-8 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400"
                                            onClick={() => setJobAddOpen(true)}
                                        >
                                            <PlusIcon className="h-5" />  Add Job
                                        </button>
                                    </div>
                                    <input
                                        className="mt-2 inline-flex justify-center w-full rounded-md border shadow-sm px-2 py-2 bg-white text-base rounded-md placeholder-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 border-gray-300"
                                        placeholder={"Search for job using name, url"}
                                        value={jobSearch}
                                        onChange={(el) => { setJobSearch(el.target.value); }}
                                    />
                                    <div className="flex flex-col gap-y-4 mt-6">
                                        {
                                            (jobsLoading && !jobs?.length) ?
                                                <div className="flex justify-center p-3">
                                                    <SpinnerCircular size={50} thickness={200} speed={100} color="#111827" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                                </div> :
                                                !!filteredJobs.length ?
                                                    filteredJobs
                                                        .map((job) => {

                                                            return (
                                                                <div
                                                                    key={`${job.id}`}
                                                                    className="relative"
                                                                >
                                                                    <div
                                                                        onClick={() => {
                                                                            setSelectedJob(job);
                                                                            setJobDetailsOpen(true);
                                                                        }}
                                                                        className={"rounded-lg shadow-sm border-[1px] border-cyan-700 flex flex-row bg-transparent cursor-pointer overflow-hidden bg-cyan-100/10 hover:bg-cyan-100/30" + (selectedJob?.id === job.id ? " border-purple-500 border-2" : " hover:border-gray-500") + (job.active ? ' ' : ' border-orange-500 border-[1px] stripes-paused hover:bg-orange-100/50')}
                                                                    >
                                                                        <div className="flex-1 lg:max-w-xs max-w-[25%] relative shadow-2xl">
                                                                            {
                                                                                <img src={job.lastImageBlobUrl} alt="" className="absolute inset-0 w-full h-full object-cover" loading="lazy" />
                                                                            }
                                                                        </div>
                                                                        <div className="p-3 flex flex-col overflow-hidden flex-1">
                                                                            <div className="flex flex-row items-end">
                                                                                <div className="text-md md:text-xl truncate text-ellipsis text-cyan-700 font-semibold" title={job.jobName}>{job.jobName}</div>
                                                                            </div>
                                                                            <div className="">
                                                                                <a className="text-sm italic truncate text-ellipsis text-gray-500 hover:text-blue-600 hover:font-medium inline" title={job.url} href={job.url} target="_blank" rel="noreferrer">{job.url}</a>
                                                                            </div>
                                                                            <div className="flex gap-2 flex-wrap mt-3">
                                                                                <div className="inline-block group whitespace-nowrap text-xs leading-5 font-medium text-sky-600 dark:text-sky-400 bg-sky-400/10 rounded-full py-1 px-3 items-center hover:bg-sky-400/20">
                                                                                    <span>Next Check:</span>
                                                                                    <span className="ml-1 group-hover:hidden">{job.active ? dayjs(job.nextOccurenceDateTimeUTC).fromNow(false) : "Paused"}</span>
                                                                                    <span className="ml-1 hidden group-hover:inline-block">{job.active ? dayjs(job.nextOccurenceDateTimeUTC).toDate().toLocaleString() : "Paused"}</span>
                                                                                </div>
                                                                                <div className="inline-block group whitespace-nowrap text-xs leading-5 font-medium text-sky-600 dark:text-sky-400 bg-sky-400/10 rounded-full py-1 px-3 items-center hover:bg-sky-400/20">
                                                                                    <span>Last Check:</span>
                                                                                    <span className="ml-1 group-hover:hidden">{dayjs(job.lastCheckDateTimeUTC).fromNow(false)}</span>
                                                                                    <span className="ml-1 hidden group-hover:inline-block">{dayjs(job.lastCheckDateTimeUTC).toDate().toLocaleString()}</span>
                                                                                </div>
                                                                                <div className="inline-block group whitespace-nowrap text-xs leading-5 font-medium text-sky-600 dark:text-sky-400 bg-sky-400/10 rounded-full py-1 px-3 items-center hover:bg-sky-400/20">
                                                                                    <span>Frequency:</span>
                                                                                    <span className="ml-1">{frequencies.find(x => x.value === job.frequencyInMinutes)?.name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="inline-flex shadow-sm rounded-full absolute top-2 right-2">
                                                                        <JobMenu
                                                                            jobId={job.id}
                                                                            editJobAction={(j) => {
                                                                                setSelectedJob(j);
                                                                                setJobAddOpen(true);
                                                                            }}
                                                                            deleteJobAction={deleteJob}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        }) :
                                                    <div className="text-center flex flex-col">
                                                        <DocumentPlusIcon className="h-12 text-gray-600 stroke-1" />
                                                        <h3 className="mt-2 text-sm font-medium text-gray-900">No jobs found</h3>
                                                        <p className="mt-1 text-sm text-gray-500">Try adjust your search or create a new job.</p>
                                                        <div className="mt-6">
                                                            <button
                                                                type="button"
                                                                onClick={() => setJobAddOpen(true)}
                                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            >
                                                                Create New Job
                                                            </button>
                                                        </div>
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    {
                        selectedJob &&
                        <JobDetails
                            open={jobDetailsOpen}
                            job={selectedJob}
                            editJob={(job) => {
                                setSelectedJob(job);
                                setJobAddOpen(true);
                            }}
                            setOpen={(b) => {
                                // deselect job on closing job preview
                                if (b === false) {
                                    setSelectedJob(null);
                                }
                                setJobDetailsOpen(b);
                            }}
                            deleteJobAction={deleteJob}
                        />
                    }
                    <WebsiteViewerModal
                        open={jobAddOpen}
                        setOpen={setJobAddOpen}
                        title={selectedJob ? "Edit Job" : "Add Job"}
                        job={selectedJob ?? undefined}
                        onJobCreationAction={getJobs}
                        onModalCloseAction={() => setSelectedJob(null)}
                    />
                </div>
            </div>
            <Footer />

        </>
    );
}

export default DashboardPage;