/* This example requires Tailwind CSS v2.0+ */
import { FC, useEffect, useState } from 'react';
import { Job, Result } from "../websiteViewer/WebsiteViewer";
import FrequencySelector, { FrequencyItem, frequencies } from "../frequencySelector/FrequencySelector";
import { useGlobalContext } from "../../providers/GlobalContext";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import dayjs from 'dayjs';
import { SpinnerCircular } from "spinners-react";
import { addUrlPrefix } from "../../utils/UrlHelpers";
import JobMenu from "../menu/JobMenu";
import Modal from "../modals/Modal";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

interface jobDetailProps {
    open: boolean;
    setOpen: (b: boolean) => void;
    editJob: (job: Job) => void;
    job: Job;
    deleteJobAction: () => void;
}

const JobDetails: FC<jobDetailProps> = (props) => {
    const { results, getResults, resultsPagingState, resultsLoading, updateJob, interceptorLoaded } = useGlobalContext();
    const [onlyShowDifferences, setOnlyShowDifferences] = useState(localStorage.getItem("OnlyShowDifferences") === "true");
    const [frequency, setFrequency] = useState<FrequencyItem>(frequencies.find(x => x.value === props.job.frequencyInMinutes) ?? frequencies[0]);
    const [selectedResult, setSelectedResult] = useState<Result | null>(null);
    const [filteredResults, setFilteredResults] = useState<Result[]>([]);

    useEffect(() => {
        if (frequency.value !== frequencies.find(x => x.value === props.job.frequencyInMinutes)?.value && !!updateJob) {
            updateJob({ ...props.job, frequencyInMinutes: frequency.value });
        }
    }, [frequency, props.job, updateJob]);

    //clear last results
    useEffect(() => {
        setSelectedResult(null);

        //filters the results
        const filtered = results
            ?.filter(x => x.predictedChange === null || parseFloat(x.predictedChange?.toLocaleString(undefined, { maximumFractionDigits: 1 })) > 0 || !onlyShowDifferences)
            ?.sort((a, b) => {
                return (new Date(b.timeCheckedDateTimeUTC).getTime() - new Date(a.timeCheckedDateTimeUTC).getTime());
            }) ?? [];

        setFilteredResults(filtered);
        setSelectedResult(filtered[0]);
    }, [props.job.id, results, onlyShowDifferences]);


    //virtual list logic
    const loadNextPage = () => {
        if (interceptorLoaded && getResults) {
            getResults(props.job.id, resultsPagingState?.continuationToken);
        }
    };

    const hasNextPage = resultsPagingState?.hasMoreResults === undefined ? true : resultsPagingState.hasMoreResults;

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage ? filteredResults.length + 1 : filteredResults.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = resultsLoading ? () => { } : loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index: number) => !hasNextPage || index < filteredResults.length;

    const handleToggle = (toggle: boolean) => {
        setOnlyShowDifferences(toggle);
        localStorage.setItem("OnlyShowDifferences", toggle.toString());
    };

    const displayResult = (result: Result) => {
        if (result.firstCheck) {
            return (<>Initial Check</>);
        }
        else if (result.cropUpdate) {
            return (<>Crop Update</>);
        }
        else {
            return (<>{result?.predictedChange?.toLocaleString(undefined, { maximumFractionDigits: 1 }) + "%"}</>);
        }
    };

    return (
        <Modal
            open={props.open}
            setOpen={props.setOpen}
            title={props.job.jobName}
            menu={
                <JobMenu
                    jobId={props.job.id}
                    editJobAction={(job) => props.editJob(job)}
                    deleteJobAction={props.deleteJobAction}
                />
            }
        >
            <div className=" flex flex-col justify-center my-6 border-[1px] border-gray-500/20 rounded-lg shadow-lg">
                <OverlayScrollbarsComponent
                    className={"os-theme-light rounded-sm md:rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 max-h-[50vh]"}
                    options={{
                        overflow: {
                            y: "scroll"
                        },
                        scrollbars: {
                            autoHide: 'never'
                        }
                    }}
                >
                    <img className="w-full max-h-full object-contain rounded-lg" src={selectedResult?.imageBlobUrl} alt="" />
                </OverlayScrollbarsComponent>
            </div>

            <div className="flex flex-col lg:flex-row-reverse gap-6">
                <table className="flex-1 h-0">
                    <tbody>
                        <tr className="h-10">
                            <td className="font-bold pr-6">Frequency</td>
                            <td className="w-full">
                                <FrequencySelector setFrequency={setFrequency} frequency={frequency} className="!w-full" buttonClassName="!justify-start" />
                            </td>
                        </tr>
                        <tr className="h-20">
                            <td className="font-bold pr-6">Url</td>
                            <td className="w-full max-w-0">
                                <a className="text-sky-500 outline-none break-all line-clamp-3 md:line-clamp-5" target="_blank" rel="noreferrer" title={props.job.url} href={addUrlPrefix(props.job.url)}>{props.job.url}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="flex-1">
                    <div className="flex gap-x-3 pb-4 justify-end">
                        <label htmlFor="OnlyShowDifferences">Only Show Differences</label>
                        <input
                            id="OnlyShowDifferences"
                            name="OnlyShowDifferences"
                            type="checkbox"
                            checked={onlyShowDifferences}
                            onChange={(e) => handleToggle(e.target.checked)}
                            className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded"
                        />
                    </div>
                    <div className="flex border-b-2 bg-sky-900 rounded-t-lg">
                        <div className="whitespace-nowrap py-4 pl-4 text-md font-bold text-white flex-1 sm:pl-6">Date</div>
                        <div className="whitespace-nowrap px-3 py-4 text-md font-bold text-white flex-1 text-center">Percent Change</div>
                    </div>
                    <div className="flex flex-col h-60 ">
                        <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={itemCount}
                            loadMoreItems={loadMoreItems}
                        >
                            {({ onItemsRendered, ref }) => (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height}
                                            itemCount={itemCount}
                                            itemSize={50}
                                            width={width}
                                            onItemsRendered={onItemsRendered}
                                            ref={ref}
                                            className="[&>*]:divide-y [&>*]:divide-gray-200 [&>*]:bg-white rounded-b-lg"
                                        >
                                            {({ index, style }) => {
                                                const result = filteredResults[index];
                                                if (!isItemLoaded(index)) {
                                                    return (
                                                        <div className="flex justify-center p-3">
                                                            <SpinnerCircular size={50} thickness={200} speed={100} color="#111827" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div key={result.id} style={style}
                                                        className={"cursor-pointer flex " + (result.id === selectedResult?.id ? " bg-blue-200" : " hover:bg-gray-200")}
                                                        onClick={() => { setSelectedResult(result); }}>
                                                        <div className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 flex-1 sm:pl-6">
                                                            {dayjs(result.timeCheckedDateTimeUTC).format("DD/MM/YYYY hh:mm a")}
                                                        </div>
                                                        <div className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex-1 text-center">{displayResult(result)}</div>
                                                    </div>
                                                );
                                            }}
                                        </List>
                                    )}
                                </AutoSizer>
                            )}
                        </InfiniteLoader>
                    </div>
                </div>
            </div>
        </Modal >
    );
};
export default JobDetails;
