import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import HomePage from "././pages/HomePage";
import ContactPage from "./pages/ContactPage";
import { HelmetProvider } from "react-helmet-async";
import AboutPage from "./pages/AboutPage";
import CheckoutPage from "./pages/CheckoutPage";
import CancelPlanPage from "./pages/CancelPlanPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import PlansPage from "./pages/PlansPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import NotFoundPage from "./pages/NotFoundPage";
import { GlobalProvider } from "./providers/GlobalContext";
import { Layout } from "./Layout";
import DashboardPage from "./pages/DashboardPage";
import JobCreatedPage from "./pages/JobCreatedPage";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication, EventType } from "@azure/msal-browser";
import SmoothScroll from "./components/smoothScroll/SmoothScroll";

interface AppProps {
  instance: IPublicClientApplication;
  authSettings: any;
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App: React.FC<AppProps> = ({ instance, authSettings }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Listen for sign-in event and set active account
  // This lives here so that we can use navigate (page wont refresh)
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        instance.setActiveAccount(account);
        const state = event.payload.state;
        if (state) {
          const pageUrl = new URLSearchParams(state).get("page_url");
          if (pageUrl && pageUrl !== pathname) {
            if (pageUrl.startsWith("http")) {
              window.location.replace(pageUrl);
            } else {
              navigate(pageUrl);
            }
          }
        }
      }
    });

    return () => {
      callbackId && instance.removeEventCallback(callbackId);
    };
  }, [instance, navigate, pathname]);

  return (
    <MsalProvider instance={instance}>
      <HelmetProvider>
        <GlobalProvider authSettings={authSettings}>
          <Layout>
            <SmoothScroll>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/job-created" element={<JobCreatedPage />} />
                <Route path="/payment-success" element={<PaymentSuccessPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/plans" element={<PlansPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/cancel-plan" element={<CancelPlanPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </SmoothScroll>
          </Layout>
        </GlobalProvider>
      </HelmetProvider>
    </MsalProvider>
  );
};

export default App;
