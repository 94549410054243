import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import { useGlobalContext } from "../providers/GlobalContext";
import { CheckIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";

function CancelPlanPage() {
  const { pricingPlans, getPricingPlans, user, cancelPlan } = useGlobalContext();

  const lastInvoice = user?.activePlan?.invoices?.sort(
    (a, b) => new Date(b.endDateUTC).getTime() - new Date(a.endDateUTC).getTime()
  )[0];
  const activePricingPlan = pricingPlans?.find((x) => x.id === user?.activePlan?.planId);

  useEffect(() => {
    if (getPricingPlans) {
      getPricingPlans();
    }
  }, [getPricingPlans]);

  const handleCancelPlan = useCallback(
    (planId: string) => {
      if (cancelPlan) {
        cancelPlan(planId);
      }
    },
    [cancelPlan]
  );

  return (
    <>
      <Helmet>
        <title>Compra - Cancel Plan</title>
      </Helmet>
      <div className="bg-gray-50 h-full flex flex-col">
        <Navbar />
        <main className="flex-1 bg-gray-100">
          <div className="max-w-md mx-auto py-16 px-4 sm:max-w-3xl sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6 relative ">
                <h3 className="text-2xl leading-6 font-medium text-gray-900 mb-4">Cancel Plan</h3>
                <div>
                  <div className="mt-4">
                    You are currently subscribed to the <span className="font-bold">{activePricingPlan?.name}</span> {" "}
                    which is scheduled to renew on{" "}
                    <span className="font-bold">
                      {dayjs(new Date(lastInvoice?.endDateUTC ?? "")).format("DD/MM/YYYY")}
                    </span>
                    .
                    <div className="mt-4">
                      Your plan includes the following features:
                      <ul className="mt-4 space-y-3 text-sm leading-6 text-gray-600">
                        {activePricingPlan?.features?.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="mt-8 italic">
                    Please note that you will keep all of your plan benefits until the end of the current billing
                    period. After this, you will be downgraded to the free plan.
                  </div>
                  <div className="mt-8">
                    <div className="mb-4 font-bold">Are you sure you want to cancel?</div>
                    {activePricingPlan && (
                      <button
                        className="text-orange-600 ring-2 ring-inset ring-orange-200 hover:ring-orange-300  rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => handleCancelPlan(activePricingPlan.id)}
                      >
                        Cancel Plan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default CancelPlanPage;
