/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment } from 'react';
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { TrashIcon, PlayIcon, PauseIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useGlobalContext } from "../../providers/GlobalContext";
import { Float } from '@headlessui-float/react';
import { Job } from "../websiteViewer/WebsiteViewer";

interface jobMenuProps {
    jobId: string;
    editJobAction: (job: Job) => void;
    deleteJobAction?: () => void;
}

const JobMenu: FC<jobMenuProps> = (props) => {
    const { pauseJob, unpauseJob, deleteJob, jobs } = useGlobalContext();
    const selectedJob = (jobs ?? []).find(x => x.id === props.jobId);
    return (
        <Menu as="div" className="-ml-px relative block">
            <Float autoPlacement={{ alignment: "start" }} autoUpdate offset={4}>
                <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-full border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    <span className="sr-only">Open options</span>
                    <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="z-20 origin-top-right mt-2 -mr-1 w-56 rounded-md shadow-2xl bg-white border-[1px] border-gray-300 focus:outline-none">
                        <div className="divide-y divide-gray-300">
                            <Menu.Item>
                                <button
                                    className={"block px-4 py-2 text-lg w-full text-left flex items-center hover:bg-blue-100/50"}
                                    onClick={() => selectedJob?.active ? pauseJob && pauseJob(props.jobId) : unpauseJob && unpauseJob(props.jobId)}
                                >
                                    {selectedJob?.active ? <>
                                        <PauseIcon className="text-orange-500 h-6 self-center mr-2" />
                                        Pause
                                    </> : <>
                                        <PlayIcon className="text-green-500 h-6 self-center mr-2" />
                                        Unpause
                                    </>}
                                </button>
                            </Menu.Item>
                            {
                                selectedJob &&
                                <Menu.Item>
                                    <button
                                            className={"block px-4 py-2 text-lg w-full text-left flex items-center hover:bg-blue-100/50"}
                                        onClick={() => props.editJobAction(selectedJob)}
                                    >
                                        <PencilIcon className="text-blue-500 h-6 self-center mr-2" />
                                        Edit Crop
                                    </button>
                                </Menu.Item>
                            }
                            <Menu.Item>
                                <button
                                    className={"block px-4 py-2 text-base w-full text-left flex items-center hover:bg-blue-100/50"}
                                    onClick={() => {
                                        deleteJob && deleteJob(props.jobId);
                                        props.deleteJobAction && props.deleteJobAction();
                                    }}
                                >
                                    <TrashIcon className="text-red-500 h-6 self-center mr-2" />
                                    Delete
                                </button>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Float>
        </Menu>
    );


};
export default JobMenu;