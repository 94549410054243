import { RedirectRequest } from "@azure/msal-browser";
import { AuthSettings } from "../providers/GlobalContext";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = (authSettings: AuthSettings) => ({
    auth: {
        clientId: authSettings.clientId,// "ae81cc81-88d7-4be1-ae41-8364ad0d6b6b", // This is the ONLY mandatory field that you need to supply.
        authority: authSettings.profileSigninSignupUrl,//b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [new URL(authSettings.profileSigninSignupUrl).host],//[b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
});

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequestRedirect = (authority: string, pageUrl: string, idToken?: string, loginHint?: string) => {
    let queryParams: { [key: string]: string; } = {};

    if (!!idToken) {
        queryParams["id_token_hint"] = idToken;
    }
    let redirectRequest: RedirectRequest = {
        scopes: ["https://compraltd.onmicrosoft.com/monitor-api/Monitor.Read"],
        authority: authority,
        loginHint: loginHint,
        extraQueryParameters: queryParams,
        state: `page_url=${pageUrl}`
    };


    return redirectRequest;
};


///**
// * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
// * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
// */
//export const protectedResources = {
//    apiHello: {
//        endpoint: "http://localhost:5000/hello",
//        scopes: ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"], // e.g. api://xxxxxx/access_as_user
//    },
//};
